import { convertToDefaultDateFormat } from '@/util';

export default class FormattedTextExam {
  constructor(exam) {
    this.exam = exam;
  }

  convert() {
    if (this.exam.dateFrom && this.exam.dateTo) {
      return {
        ...this.exam,
        text: `${this.exam.location} (${convertToDefaultDateFormat(this.exam.dateFrom)} - ${convertToDefaultDateFormat(this.exam.dateTo)}) ${this.exam.timeFrom} - ${this.exam.timeTo}`
      };
    }

    if (this.exam.exam_date_future_days) {
      return {
        ...this.exam,
        text: `${this.exam.location} ${this.exam.exam_date_future_days} days ${this.exam.timeFrom} - ${this.exam.timeTo}`
      };
    }

    return {
      ...this.exam,
      text: `${this.exam.location} ${convertToDefaultDateFormat(this.exam.date)} ${this.exam.timeFrom} - ${this.exam.timeTo}`
    };
  }
}
