<script>
import api from "@/services/api/api-cm"
import Svc from '../../../course_managment/core/cm-service'
import FormattedTextExam from '../../core/models/formattedTextExam.js'

export default {
  name: 'ExamChoose',

  render() {
    return this.$scopedSlots.default({
      hasCourse: this.hasCourse,
      loading: this.loading,
      list: this.list,
      loadData: this.loadData,
      addToList: this.addToList,
      selectedExam: this.selectedExam,
      loadWithInform: this.loadWithInform,
      hasExam: this.hasExam
    })
  },

  props: {
    courseID: {
      type: Number
    },
    activeExam: Object,
    candidateLicenseTypes: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    svc: new Svc(api),
    loading: false,
    list: []
  }),

  computed: {
    hasCourse() {
      if (this.courseID) return true
      return false
    },

    selectedExam() {
      if(!this.activeExam || !this.activeExam.ID) return {}
      return this.list.find(item => item.ID === this.activeExam.ID)
    },

    hasExam() {
      if (!this.activeExam || !this.activeExam.ID) return false
      return true
    }
  },

  mounted() {
    if (this.courseID) this.loadData(this.courseID)
  },

  methods: {
    async loadWithInform(data) {
      await this.loadData(data)
      this.$emit('loaded', this.list)
    },

    async loadData(data) {
      try {
        this.loading = true
        let res = await this.svc.getConfig(data)
        if(this.candidateLicenseTypes.length) {
          res = res.filter(item => this.candidateLicenseTypes.includes(item.licenseType) )
        }
        this.list = res.map(item => new FormattedTextExam(item).convert())
      } catch (error) {
        console.log(error)
      }
      finally {
        this.loading = false
      }
    },

    addToList(item) {
      this.list.push(item)
    }
  }
}
</script>
